export default [
  {
    title: 'Quản lý liên hệ',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Thông tin liên hệ',
        route: 'contact-list',
      },
      {
        title: 'Khách hàng liên hệ',
        route: 'contact-service-list',
      },
    ],
  },
]
