export default [
  {
    title: 'Quản lý xe',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Loại xe',
        route: 'car-catalog-list',
      },
    ],
  },
]
