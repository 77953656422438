export default [
  {
    title: 'Quản lý bài viết',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Giới thiệu',
        route: 'nc-gioi-thieu-list',
      },
      {
        title: 'Tin tức',
        route: 'nc-tin-tuc-list',
      },
      {
        title: 'Slide trang chủ',
        route: 'slide-main-list',
      },
    ],
  },
]
