// danh sách menu cho sở giáo dục

import user from '../user'
// import business from '../business-deparment'
// import workers from '../workers-department'
// import statistical from '../statistical'
// import news from '../news'
// import setting from '../setting'
// import occupationalSafetyHealth from '../occupational-safety-health-deparment'
// import connective from '../connective'
// import archive from '../archiveRecord'
// import categoryFile from '../categoryFile'
// import service from '../service-deparment'
import category from '../category-manager/category'
import carManager from '../car-manager'
import newContant from '../new-contant-manager'
import contact from '../contact-manager'

export default [...user, ...category, ...carManager, ...newContant, ...contact]
