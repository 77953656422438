<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <div>
        <vuexy-logo
          class="brand-logo logo-home "
        />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold login_title"
            title-tag="h2"
          >
            ĐĂNG NHẬP HỆ THỐNG
          </b-card-title>
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Tên đăng nhập"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label>Mật khẩu</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Quên mật khẩu?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Nhớ tài khoản
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="login_button"
                block
                :disabled="loading"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                ></div>
                <span v-if="loading"> Kết nối server ...</span>
                <span v-else>Đăng nhập</span>
              </b-button>
            </b-form>
          </validation-observer>

          <!--          <b-card-text class="text-center mt-2">-->
          <!--            <span>Bạn là doanh nghiệp? </span>-->
          <!--            <b-link :to="{name:'auth-register'}">-->
          <!--              <span>&nbsp;Tạo mới tài khoản</span>-->
          <!--            </b-link>-->
          <!--          </b-card-text>-->

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              <a
                href="https://itphonui.com"
                target="_blank"
                style="color: red!important;font-weight: bold;"
              >
                ITPhoNui
              </a>
            </div>
          </div>

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import businessMenu from '@/navigation/vertical/business-menu'
import departmentMenu from '@/navigation/vertical/department-menu'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { alertError } from '@/views/ndev-component/constants/NCoreHelper'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-image.svg'),
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-image.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.$router.push({ name: 'dashboard-ecommerce' })
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            this.loading = true
            useJwt.login({
              userName: this.userEmail,
              password: this.password,
            })
              .then(async response => {
                const token = response.data?.accesstoken || ''

                if (token) {
                  localStorage.setItem(jwtDefaultConfig.storageTokenKeyName, `Bearer ${token}`)
                  localStorage.setItem(jwtDefaultConfig.storageRefreshTokenKeyName, response.data?.refreshToken)
                  localStorage.setItem('token', `Bearer ${token}`)
                  localStorage.setItem('refreshToken', response.data?.refreshToken)
                }
                await this.accountInfo()
                this.$ability.update([{
                  action: 'manage',
                  subject: 'all',
                }])
                await this.$router.push({ name: 'dashboard-ecommerce' })
                this.loading = false
              })
              .catch(error => {
                this.loading = false
                alertError(error)
              })
              // .then(response => {
              //   useJwt.setRefreshToken(response.data.refreshToken)
              //   useJwt.setToken(response.data?.data)
              //   this.$http.get('/Account/accountInfo')
              //     .then(respon => {
              //       localStorage.setItem('userData', JSON.stringify(respon.data))
              //     })
              //   const userData = JSON.parse(localStorage.getItem('userData'))
              //   console.log('userData', userData)
              //   this.$ability.update(userData.ability)

            //   // ? This is just for demo purpose as well.
            //   // ? Because we are showing eCommerce app's cart items count in navbar
            //   this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

            //   // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            //   this.$router.push(getHomeRouteForLoggedInUser(userData.role))
            //     .then(() => {
            //       this.$toast({
            //         component: ToastificationContent,
            //         position: 'top-right',
            //         props: {
            //           title: `Welcome ${userData.fullName || userData.username}`,
            //           icon: 'CoffeeIcon',
            //           variant: 'success',
            //           text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            //         },
            //       })
            //     })
            //     .catch(error => {
            //       this.$refs.loginForm.setErrors(error.response.data.error)
            //     })
            // })
          }
        })
    },
    // Lấy thông tin người dùng
    async accountInfo() {
      const { data } = await useJwt.accountInfo()
      if (data) {
        localStorage.setItem('userData', JSON.stringify(data))
        switch (data.roleIdentity.toUpperCase()) {
          case 'DEPARTMENTOFEDUCATION':
            localStorage.setItem(jwtDefaultConfig.menuItems, JSON.stringify(departmentMenu))
            this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', departmentMenu)
            break
          case 'BUSSINESS':
            localStorage.setItem(jwtDefaultConfig.menuItems, JSON.stringify(businessMenu))
            this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', businessMenu)
            break
          default:
            localStorage.setItem(jwtDefaultConfig.menuItems, JSON.stringify(departmentMenu))
            this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', departmentMenu)
            break
        }
      }
      // const data = await this.$store.dispatch('users/accountInfo')
    },
    goToHome() {
      this.$router.push({
        name: 'home',
        params: { check: true },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.logo-home {
  cursor: pointer;
}
.login_title{
  font-weight: bold;
  color: red;
  text-align: center;
  font-family: monospace;
  font-size: 30px;
}
</style>
