export default [
  {
    title: 'Danh mục',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Nhóm tin tức',
        route: 'group-new-list',
      },
      {
        title: 'Nhóm giới thiệu',
        route: 'group-about-list',
      },
      {
        title: 'Thông số kỹ thuật',
        route: 'specification-list',
      },
    ],
  },
]
