export default [
  {
    title: 'Dịch vụ làm việc',
    icon: 'PhoneIcon',
    route: 'employment-service',
  },
  {
    title: 'Nộp báo cáo',
    icon: 'BarChartIcon',
    route: 'submit-report',
  },
]
